:root {
    --text: #2b3044;
    --line: #bbc1e1;
    --line-active: #F16C20;
  }
* {
    box-sizing: border-box;
}

body {
    font-family: work-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    background-color: #f5f0ed;
    min-height: 100%;
    overflow-x: hidden;
}
.header {
    display: flex;
    height: 100vh;
    opacity: 1;
    transform: translateX(0px) translateY(0px) translateZ(0px);
    transition: opacity 800ms ease 0s, transform 800ms ease 0s;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-style: normal;
}

.logo-header {
    height:55px;
}

.logo-header:hover {
    color: #fff;
}

.aboutus {
    height: 80vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.works {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.p-aboutus {
    font-weight: 400;
    font-size:1.4em;
    text-align: left;  

}

.p-works {
    font-weight: 400;
    font-size:2.5em;
    text-align: center;  
    color: #fff;
    margin-bottom: 50px;
}
.container-aboutus {
    flex:1;
    justify-content: center;
    align-items: center;
    padding-right: 10%;
    padding-left: 10%;
}
.container-works {
    flex:1;
    justify-content: center;
    align-items: center;
    padding-right: 10%;
    padding-left: 10%;
}

.works-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom:50px
}

.work-card {
    width: 100%;
    height: 500px;
}

.works-cards > div {
    flex: 40%;
    box-shadow: 0 0 0 1px black;
    margin: 10px;
    padding: 10px;
}


.work-text {
    font-weight: 400;
    font-size:1em;
    text-align: left;  
}

.work-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 10px;
}
.work-title {
    font-weight: 400;
    font-size:1.2em;
    text-align: left;
    font-style: bold; 
    margin-bottom: 0rem;

}
.work-description {
    margin:0px;
    font-weight: 300;
    font-size:1em;
    text-align: left;
    color:#a7a4a29f;
}
.bg-dark {
    background-color: #0a0a0a!important;
    color: #fff;
    transition: background-color 400ms ease 0s,color 400ms ease 0s;
}

.bold{
    font-family: work-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.xbold {
    font-family: work-sans, sans-serif;
    font-weight: 800;
    font-style: normal;
}

.light {
    color: #fff!important;
}
.dark {
    color: #000!important;
}

.bg-light {
    background-color: #f5f0ed;
    color: #000;
    transition: background-color 400ms ease 0s,color 400ms ease 0s;
}

.footer {
    width: 100%;
    display: flex;
    padding: 0px 50px 20px;
    font-size: 0.8em;
    justify-content: space-between;
}
.footer-social {
    flex:1;
    display: block;
    text-align: right;
    
}

.footer-social a {
    font-size: 1rem;
    margin-right: 20px;
    color: #fff;
    transition: color 400ms ease 0s;
}
.icon {
    transition: .5s;
    z-index: 3;
  }

a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
  }

  a:before {
    content: "";
    position: absolute;
    transition: .5s;
    z-index: 2;
  }
  

.footer-link {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: #F16C20;
    margin-left: 5px;
    margin-right: 5px;
    color:#fff;

}

  
.footer-copyright{
    flex:1;
}

.footer-copyright p {
    font-weight: 700;
    position: relative;
    overflow: hidden;
    background:linear-gradient(to right, #F16C20, #F16C20 50%, white 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 500ms ease;

}
.footer-copyright p:hover {
    background-position: 0 100%;
}


.footer-credits a{
        display: inline-block;
        position: relative;
        text-decoration: none;
        color: inherit;
        margin: 0 var(--spacing, 0px);
        transition: margin 0.25s;
}

/*Stroke length 491.278564453125 */
.footer-credits a svg {
    width: 105px;
    height: 40px;
    position: absolute;
    left: 51%;
    bottom: 0;
    transform: translate(-50%, 5px) translateZ(0);
    fill: none;
    stroke: var(--stroke, var(--line));
    stroke-linecap: round;
    stroke-width: 2px;
    stroke-dasharray: var(--offset, 108px) 496px;
    stroke-dashoffset: 5px;
    transition: stroke 0.25s ease var(--stroke-delay, 0s), stroke-dasharray 0.35s;
  }
  .footer-credits a:hover {
    --spacing: 8px;
    --stroke: var(--line-active);
    --stroke-delay: 0.1s;
    --offset: 490px;
  }

.link {
    text-decoration: none;
    color:#fff;
}

@media only screen and (max-width: 600px)  {
    .works-cards > div {
        flex: 100%;
        box-shadow: 0 0 0 1px black;
        margin: 10px;
        padding: 10px;
    }

    .footer {
        flex-direction: row;
    }
    .footer-credits{
        display: none;
    }
}

